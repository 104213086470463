import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import moment from "moment"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { Button, Modal, Row, Col, Typography } from "antd"
import _orderBy from "lodash/orderBy"
import ScrollAnimation from "react-animate-on-scroll"

import Layout from "../app/layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import MainSlider from "../components/MainSlider"
import CardSmartLink from "../components/CardSmartLink"
import Blockquote from "../components/Blockquote"
import SectionWithVideo from "../components/SectionWithVideo"
import CardNews from "../components/CardNews"
import LiveVideo from "../components/LiveVideo"

moment.updateLocale("id", {
  months: [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
  weekdays: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
})

const IndexPage = ({ data: { homePage: data, allNews, lives } }) => {
  const { t } = useI18next()

  const news =
    allNews.nodes.length > 0
      ? [allNews.nodes[0].highlighted_news].concat(allNews.nodes[0].item)
      : []
  const orderedInformations = _orderBy(data.information, ["order"], "asc")

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { Title } = Typography

  useEffect(() => {
    if (data.pop_up.publish === 1) {
      setIsModalOpen(true)
    }
  }, [data.pop_up.publish])

  const handleOk = () => {
    setIsModalOpen(false)
    window.open(data.pop_up.button_url, "_blank", "noopener,noreferrer")
  }

  return (
    <Layout module_name="home" page_name="home">
      <Seo title={t("Home")} module_name="home" page_name="home" />

      <MainSlider sliderContent={data.banner} />
      
      {data.information.length > 0 && (
        <div className="section">
          <Container>
            <Row gutter={{ xs: 8, sm: 16, md: 60 }}>
              {orderedInformations.map(function (item, i) {
                return (
                  <Col span={24} md={8} key={`main-slider-${i}`}>
                    <CardSmartLink
                      image={item.icon}
                      title={item.title}
                      desc={item.description}
                      buttonURL={item.button_url}
                    />
                  </Col>
                )
              })}
            </Row>
          </Container>
        </div>
      )}

      {news.length > 0 && (
        <div className="section" style={{ paddingTop: 80 }}>
          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <div className="text-center">
                <h3 className="section-title">{t("Berita Terbaru")}</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={2}>
              <Row gutter={30} style={{ marginTop: 30, marginBottom: 30 }}>
                {news.slice(0, 3).map(function (item, i) {
                  return (
                    <Col md={8} key={`news-${i}`}>
                      <CardNews
                        title={item.title}
                        category={item.news_category}
                        image={item.image}
                        url={"/informasi/berita/" + item.slug}
                        date={item.created_at}
                      />
                    </Col>
                  )
                })}
              </Row>
              <div className="text-center">
                <Button size="large" href="/informasi/berita/">
                  {t("Lihat Semua Berita")}
                </Button>
              </div>
            </ScrollAnimation>
          </Container>
        </div>
      )}

      {data.page_setting && (
        <div className="section">
          <Container>
            <Row>
              <Col md={14} offset={6}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Blockquote
                    content={data.page_setting.section_testimony_text}
                    name={data.page_setting.section_testimony_name}
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      
      {data.page_setting && (
        <SectionWithVideo
          videoBg={data.page_setting.section_about_video_thumbnail}
          playBtnText={t("Putar Video")}
          videoId={data.page_setting.section_about_video_id}
        >
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
            <h3 className="section-title">
              {data.page_setting.section_about_title}
            </h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={2}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.section_about_content,
              }}
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={3}>
            <h4 className="section-subtitle" style={{ marginTop: 20 }}>
              {t("Sebaran Penerima Layanan")}
              <span>per {data.page_setting.section_about_tanggal_update}</span>
            </h4>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={4}>
            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
              {({ isVisible }) => (
                <Row gutter={20} style={{ marginTop: 24, marginBottom: 24 }}>
                  <Col span={24} md={8}>
                    <h4 className="semi-bold" style={{ marginBottom: 0 }}>
                      {isVisible ? (
                        <CountUp
                          end={
                            data.page_setting.section_about_penerima_beasiswa
                          }
                          duration={1}
                          separator={"."}
                        />
                      ) : null}
                      <sub style={{ bottom: 0, marginLeft: "4px" }}>
                        {t("org")}
                      </sub>
                    </h4>
                    <p>{t("Penerima Beasiswa")}</p>
                  </Col>
                  <Col span={24} md={8}>
                    <h4 className="semi-bold" style={{ marginBottom: 0 }}>
                      {isVisible ? (
                        <CountUp
                          end={data.page_setting.section_about_alumni}
                          duration={1}
                          separator={"."}
                        />
                      ) : null}
                      <sub style={{ bottom: 0, marginLeft: "4px" }}>
                        {t("org")}
                      </sub>
                    </h4>
                    <p>Alumni</p>
                  </Col>
                  <Col span={24} md={8}>
                    <h4 className="semi-bold" style={{ marginBottom: 0 }}>
                      {isVisible ? (
                        <CountUp
                          end={data.page_setting.section_about_dana_abadi}
                          duration={1}
                          decimals={2}
                          decimal=","
                        />
                      ) : null}
                      <sub style={{ bottom: 0, marginLeft: "4px" }}>
                        T Rupiah
                      </sub>
                    </h4>
                    <p>{t("Dana Abadi")}</p>
                  </Col>
                  <Col span={24} md={8}>
                    <h4 className="semi-bold" style={{ marginBottom: 0 }}>
                      {isVisible ? (
                        <CountUp
                          end={data.page_setting.section_about_riset_didanai}
                          duration={1}
                          separator={"."}
                        />
                      ) : null}
                      <sub style={{ bottom: 0, marginLeft: "4px" }}>
                        {t("judul")}
                      </sub>
                    </h4>
                    <p>{t("Riset Didanai")}</p>
                  </Col>
                  <Col span={24} md={8}>
                    <h4 className="semi-bold" style={{ marginBottom: 0 }}>
                      {isVisible ? (
                        <CountUp
                          end={data.page_setting.section_about_awardee_afirmasi}
                          duration={1}
                          separator={"."}
                        />
                      ) : null}
                      <sub style={{ bottom: 0, marginLeft: "4px" }}>
                        {t("org")}
                      </sub>
                    </h4>
                    <p>{t("Afirmasi dan Targeted")}</p>
                  </Col>
                </Row>
              )}
            </VisibilitySensor>
            <Button className="btn-outline-white" size="large">
              <Link to="/tentang/selayang-pandang">
                {t("Lihat Lebih Lanjut")}
              </Link>
            </Button>
          </ScrollAnimation>
        </SectionWithVideo>
      )}

      {data.page_setting && data.page_setting.is_display_live === 1 && (
        <div className="section">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <LiveVideo
              image={`https://img.youtube.com/vi/${lives.video_url}/0.jpg`}
              title={lives.title}
              desc={lives.short_description}
              video_url={lives.video_url}
            />
          </ScrollAnimation>
        </div>
      )}

      {data.pop_up && data.pop_up.publish === 1 && (
        <Modal
          className="popup-modal"
          visible={isModalOpen}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              {data.pop_up.button_text}
            </Button>,
          ]}
          onCancel={() => setIsModalOpen(false)}
        >
          <Title level={3} className="text-center">
            {data.pop_up.title}
          </Title>

          <img src={data.pop_up.image} alt={data.pop_up.title} width="100%" />

          <div
            className="text-center text-highlight"
            dangerouslySetInnerHTML={{ __html: data.pop_up.description }}
          ></div>
        </Modal>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery($language: String!) {
    lives(lang: { eq: $language }) {
      content
      short_description
      slug
      title
      event_date
      event_time
      video_url
      broadcast_platform
    }
    homePage(lang: { eq: $language }) {
      banner {
        banner_image
        banner_image_mobile
        button_text
        button_url
        text_color
        title
        order
      }
      information {
        icon
        button_url
        description
        title
        order
      }
      page_setting {
        created_at
        is_display_live
        section_about_alumni
        section_about_awardee_afirmasi
        section_about_content
        section_about_dana_abadi
        section_about_penerima_beasiswa
        section_about_riset_didanai
        section_about_tanggal_update(formatString: "DD MMMM YYYY")
        section_about_title
        section_about_video_id
        section_about_video_thumbnail
        section_live_event_date(formatString: "DD MMMM YYYY")
        section_live_short_description
        section_live_speaker_name
        section_live_speaker_title
        section_live_start_time
        section_live_title
        section_live_video_id
        section_testimony_name
        section_testimony_text
        updated_at
      }
      pop_up {
        button_text
        button_url
        description
        image
        title
        publish
      }
    }
    allNews(
      sort: { fields: item___created_at, order: DESC }
      filter: { lang: { eq: $language } }
    ) {
      nodes {
        highlighted_news {
          title
          slug
          news_category
          image
          created_at
        }
        item {
          title
          slug
          news_category
          image
          created_at
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
